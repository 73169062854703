import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"


const PassePartoutPage = () => (
  <Layout>
    <SEO title="Passe-partouts Gunnar" />
    <br /><br />
  </Layout>

)
export default PassePartoutPage
